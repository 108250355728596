<style lang="less" scoped>
	img{
	  width: 100%;
	  height: 100%;
	}
.img1 {
  width: 14px;
  height: 16px;
  vertical-align: middle;
}
// .img2 {
//   width: 16px;
//   height: 16px;
//   vertical-align: middle;
//   margin-right: 5px;
// }
.box {
  padding: 0 20px;
}
.tabs {
  font-size: 20px;
  font-weight: 500;
  color: #666666;
  div {
    cursor: pointer;
    text-align: center;
    width: 140px;
    height: 66px;
    line-height: 66px;
    margin-right: 70px;
    box-sizing: border-box;
  }
  .active {
    color: #0090f0;
    border-bottom: 4px solid #0090f0;
  }
}
.i1 {
  font-size: 18px;
}
/deep/ .el-table tbody tr:hover > td {
  color: #0090f0;
}

.kecheng_pinglun  {
  padding: 20px 30px;
  box-sizing: border-box;
  .pinglun_box  {
    color: #333;
    .pinglun_num  {
      font-size: 16px;
      font-weight: 700;
    }
    .pinglun_list  {
      display: flex;
      padding: 20px 0;
      border-bottom: 1px solid rgba(237, 237, 237, 1);
      box-sizing: border-box;
      img  {
        width: 41px;
        height: 41px;
        border-radius: 50%;
        margin-right: 10px;
      }
        .ping_rt  {
        width: 93%;
        .ping_name  {
          font-size: 14px;
          font-weight: 700;
        }
        .ping_desc  {
          font-size: 16px;
          margin-top: 12px;
          margin-bottom: 20px;
        }
        .ping_time  {
          font-size: 12px;
          color: #999;
        }
      }
    }
    .pinglun_list:last-child  {
      border: none;
    }
  }
}

.end {
  justify-content: flex-end;
  .submit {
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100px;
    border-radius: 20px;
    text-align: center;
    font-size: 16px;
    color: #fff;
    height: 40px;
    line-height: 40px;
    background-color: #0090f0;
  }
}
.intro {
  padding: 0 25px;
}
</style>
<template>
  <div>
    <div class="flex1 tabs">
      <div
        class="tab"
        :class="{ active: active == index }"
        @click="change(index)"
        v-for="(item, index) in tabs"
        :key="index"
      >
        {{ item }}
      </div>
    </div>
    <div class="box" v-if="active == 0">
      <el-table
        :data="muluVal"
        style="width: 100%; margin-bottom: 20px"
        row-key="id"
        :show-header="header"
        default-expand-all
        :header-cell-style="headerStyle"
        :header-row-style="{ height: '66px' }"
        @row-click="handdle"
        :cell-style="cellStyle"
        :tree-props="{ children: 'section' }"
      >
        <el-table-column prop="subsection" label="章节" width="760">
          <template slot-scope="scope">
            <i v-if="scope.row.play == 2" class="i1 iconfont icon-bofang"></i>
            <i
              v-if="scope.row.play == 1"
              class="i1 el-icon-video-camera-solid"
            ></i>
            {{ scope.row.subsection }}
          </template>
        </el-table-column>
        <el-table-column label width="70">
          <template slot-scope="scope">
            <div v-if="courseInfo.is_buy == 0">
              <img
                v-if="scope.row.is_free == 0"
                class="img1"
                src="../../../assets/img/live/icon_2_.png"
              />
            </div>
            <!-- <el-button type="text" @click="handleDelete1(scope.$index, scope.row)"></el-button> -->
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 课程简介 -->
    <div class="intro" v-if="active == 1" v-html="courseInfo.content"></div>
    <!-- 课程评论 -->
    <div v-if="active == 2" class="kecheng_pinglun">
      <div class="comment">
        <el-input
          type="textarea"
          :autosize="{ minRows: 4 }"
          placeholder="请输入内容"
          v-model="textarea"
        ></el-input>
        <div class="flex end">
          <div class="submit" @click="submit">提交</div>
        </div>
      </div>
      <div class="pinglun_box">
        <div class="pinglun_num">全部评论（{{ commentList.length }}）</div>
        <div
          class="pinglun_list"
          v-for="(item, index) in commentList"
          :key="index"
        >
          <img :src="item.face" alt />
          <div class="ping_rt">
            <div class="ping_name">{{ item.nickname }}</div>
            <div class="ping_desc">{{ item.contents }}</div>
            <div class="ping_time">{{ item.create_at }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { commentList, addComment } from "../../../assets/js/course";
export default {
  props: ["muluVal", "courseInfo"],
  data() {
    return {
      user_id: JSON.parse(window.localStorage.getItem("userInfo"))
        ? JSON.parse(window.localStorage.getItem("userInfo")).id
        : "",
      active: 0,
      header: false,
      textarea: "",
      tabs: ["课程目录", "课程简介", "课程评论"],
      commentList: [],
    };
  },
  watch: {
    courseInfo: function (newVal, oldVal) {
      // console.log(newVal);
      this.courseInfo = newVal;
      // console.log(this.courseInfo);
      this.getCommentList();
    },
  },
  mounted() {},
  methods: {
    // 获取课程评论
    getCommentList() {
      commentList({
        culum_id: this.courseInfo.culum_id,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.commentList = res.data;
        }
      });
    },
    // 新增评论
    submit() {
      if (!this.user_id) {
        this.$message.error("请先登录！");
        this.$router.push({
          path: "/login",
          query: {
            active: 0,
          },
        });
        return
      }
      if (!this.textarea.trim()) {
        this.$message.error("请填写评论内容！");
        return;
      }
      addComment({
        culum_id: this.courseInfo.culum_id,
        user_id: this.user_id,
        contents: this.textarea,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.$message.success("评论发布成功！");
          this.textarea = "";
          this.getCommentList();
        }
      });
    },
    handdle(row, event, column) {
      if (!this.user_id) {
        this.$router.push({
          path: "/login",
          query: {
            active: 0,
          },
        });
        return;
      }
      if (row.section) {
        return;
      }
      if (this.courseInfo.is_buy == 0) {
        if (row.is_free == 0) {
          this.$message.error("购买后，才可观看");
          return;
        }
      }
      this.$router.push({
        path: "/video",
        query: { culum_id: row.c_id, video_id: row.id },
      });
    },
    change(i) {
      this.active = i;
    },

    headerStyle() {
      return {
        "font-size": "16px",
        height: "66px",
        padding: "0",
        color: "#333333",
        "font-weight": "400",
        "background-color": "#F8F8F8",
        border: "none",
      };
    },
    cellStyle() {
      return {
        "font-size": "15px",
        height: "66px",
        padding: "0",
        cursor: "pointer",
      };
    },
    handleDelete1(i, row) {
      // console.log(row);
    },
  },
};
</script>